import Typesense from "typesense"
import { point as turfPoint } from "@turf/helpers"
import turfBboxPolygon from "@turf/bbox-polygon"

import { clientAdaptedPoints } from "./client"
import embedParams from "../config/embedParams"

const TYPESENSE_ENV = embedParams.typesenseEnv
const TYPESENSE_HOST = (() => {
  switch (TYPESENSE_ENV) {
    case `development`:
      return `http://localhost:8108`
    case `staging`:
      return `https://ts-stg.naturkartan.se`
    case `production`:
    default:
      return `https://ts.naturkartan.se`
  }
})()
const TYPESENSE_KEY = (() => {
  switch (TYPESENSE_ENV) {
    case `development`:
      return process.env.REACT_APP_TYPESENSE_KEY_DEVELOPMENT
    case `staging`:
      return process.env.REACT_APP_TYPESENSE_KEY_STAGING
    case `production`:
    default:
      return process.env.REACT_APP_TYPESENSE_KEY_PRODUCTION
  }
})()

const getCollection = () => {
  return new Typesense.Client({
    nodes: [{ url: TYPESENSE_HOST }],
    apiKey: TYPESENSE_KEY,
    connectionTimeoutSeconds: 2,
  }).collections(`site:${TYPESENSE_ENV}`)
}

const featurizeHits = (hits) =>
  hits
    .map(({ document: hit }) => {
      const type = hit.type.toLowerCase()

      const urlPath = hit.path.replace(
        /^\/(\w{2})\//,
        `/${embedParams.language}/`
      )

      const urlParams = embedParams.guide
        ? `?guide_id=${embedParams.guide}`
        : ``

      const url = `${embedParams.naturkartanBase}${urlPath}${urlParams}`

      const mainCategoryId = hit.main_icon_id ? String(hit.main_icon_id) : ``

      const mainCategoryHandle = hit.main_category_icon

      let mapIcon = mainCategoryHandle
        ? `${mainCategoryHandle}-${type}`
        : `none`

      if (type == `place`) {
        switch (mainCategoryId) {
          case `177`: {
            mapIcon += `-favorites`
            break
          }

          case `174`:
          case `130`: {
            mapIcon += `-nature-reserve`
            break
          }

          case `129`: {
            mapIcon += `-warning`
            break
          }

          case `128`: {
            mapIcon += `-attention`
            break
          }
        }
      }

      const trailStatusReportedAt = hit.trail_status_reported_at
        ? `${hit.trail_status_reported_at.replace(/[+-]\d\d:\d\d/, ``)}+01:00`
        : null

      // @LATERDO: implement `hit.main_category_group`
      // const mapIcon =
      //   type == `place` &&
      //   [`accessible`, `bigcrowd`, `crowd`].includes(mainCategory.slug)
      //     ? `${mainCategory.icon}-${mainCategory.slug}-${type}`
      //     : `${mainCategory.icon}-${type}`

      return turfPoint(
        [hit.marker_point[1], hit.marker_point[0]],
        {
          type: type,
          mainCategory: mainCategoryId,
          mainCategoryHandle: mainCategoryHandle,
          categories: hit.category_ids.map((id) => String(id)),
          guides: hit.guide_ids.map((id) => String(id)),
          trips: hit.trip_ids.map((id) => String(id)),
          assetsCategoryIcon: mainCategoryId || `none`,
          mapIcon: mapIcon,
          mapIconActive: `${mainCategoryHandle || `none`}-active`,
          municipality: hit.municipality_id ? String(hit.municipality_id) : ``,
          organization: hit.organization_id ? String(hit.organization_id) : ``,
          title: hit[`name_${embedParams.language}`] || hit.name_sv,
          url: url,
          published: hit.published,
          image: hit.imgix_url,
          importance: hit.importance,
          rating: hit.average_rating,
          length: hit.length,
          difficulty: hit.difficulty,
          time: hit.time,
          popularity: hit.popularity,
          showElevations: hit.show_elevations,
          wheelchairTested: hit.wheelchair_tested,
          trailStatusReportedAt: trailStatusReportedAt,
        },
        { id: String(hit.id) }
      )
    })
    .filter(Boolean)

const fetchTypesensePoints = ({ query, filter, bounds }) => {
  return new Promise((resolve, reject) => {
    const documents = getCollection().documents()
    const hits = []

    let fetchFilter = filter
    let total = 0
    let counter = -1

    if (bounds?.length) {
      bounds = [
        Math.max(bounds[2], 10),
        bounds[3] > 0 ? Math.min(bounds[3], 90) : Math.max(bounds[3], -90),
        Math.min(bounds[0], 26),
        bounds[1] > 0 ? Math.min(bounds[1], 90) : Math.max(bounds[1], -90),
      ]

      const boundsCoordinates = turfBboxPolygon(bounds)
        .geometry.coordinates[0].slice(0, -1)
        .map((c) => `${c[1]},${c[0]}`)
        .join(`,`)

      const boundsFilter = `marker_point:(${boundsCoordinates})`
      fetchFilter = [filter, boundsFilter].filter(Boolean).join(` && `)

      counter = new Promise((resolve, reject) =>
        documents
          .search({
            q: query,
            query_by: `name_sv,name_${embedParams.language}`,
            filter_by: filter,
            page: 1,
            limit: 0,
            include_fields: null,
          })
          .then((data) => resolve(data.found))
          .catch(reject)
      )
    }

    const fetch = (page = 1) => {
      return new Promise((resolve, reject) => {
        const limit = 250

        return documents
          .search({
            limit,
            page,
            q: query,
            query_by: `name_sv,name_${embedParams.language}`,
            filter_by: fetchFilter,
            sort_by: `importance:asc`,
            include_fields: [
              `id`,
              `type`,
              `name_sv`,
              `name_${embedParams.language}`,
              `path`,
              `published`,
              `importance`,
              `average_rating`,
              `length`,
              `popularity`,
              `difficulty`,
              `time`,
              `wheelchair_tested`,
              `trail_status_reported_at`,
              `imgix_url`,
              `guide_ids`,
              `municipality_id`,
              `organization_id`,
              `trip_ids`,
              `category_ids`,
              `main_category_icon`,
              `main_icon_id`,
              // `main_category_group`,
              `marker_point`,
              `show_elevations`,
            ].join(`,`),
          })
          .then((data) => {
            hits.push(...data.hits)

            const totalPages = Math.ceil(data.found / limit)
            total = data.found

            // fetching up to limit*10=2500 hits
            return totalPages > 1 && totalPages > page && data.page < 10
              ? resolve(fetch(page + 1))
              : resolve()
          })
          .catch(reject)
      })
    }

    return Promise.all([fetch(1), counter])
      .then(([, counterTotal]) => {
        const mainTotal = counterTotal >= 0 ? counterTotal : total
        return resolve({
          points: clientAdaptedPoints(featurizeHits(hits)),
          isComplete: hits.length >= mainTotal,
          total: mainTotal,
        })
      })
      .catch(reject)
  })
}

const searchTypesenseSites = ({ query, filter, limit = 10 }) => {
  return new Promise((resolve, reject) => {
    return getCollection()
      .documents()
      .search({
        q: query,
        query_by: `name_sv,name_${embedParams.language}`,
        include_fields: `id,main_icon_id,name_sv,name_${embedParams.language}`,
        sort_by: `importance:desc,_text_match:desc`,
        filter_by: filter,
        limit: limit,
      })
      .then((data) =>
        resolve(
          data.hits.map(({ document: h }) => ({
            id: String(h.id),
            title: h[`name_${embedParams.language}`] || h.name_sv,
            assetsCategoryIcon: h.main_icon_id || `none`,
          }))
        )
      )
      .catch(reject)
  })
}

export { fetchTypesensePoints, searchTypesenseSites }
