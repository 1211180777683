const tealBlue = `#2b7e9a`
const cyberYellow = `#ffd200`

export default {
  white: `#fff`,
  black: `#222`,

  gunmetal: `#08292f`,
  eagleGreen: `#0c3e47`,
  cadet: `#5c6c76`,
  slateGray: `#627885`,
  darkSlateGray: `#325e58`,
  lightGray: `#d8d8d8`,
  alloyOrange: `#ba5b00`,
  aliceBlue: `#ebf1f3`,
  pewterBlue: `#869ba1`,
  maximumRed: `#dc1e1e`,
  carmine: `#961919`,
  seaGreen: `#1a8745`,
  indigoDye: `#1f4675`,
  russet: `#7f532a`,
  maximumPurple: `#7c458f`,
  fuchsiaRose: `#b83069`,
  tealBlue: tealBlue,
  cyberYellow: cyberYellow,
  timberwolf: `#e2dbd2`,
  khakiWeb: `#c6b9a6`,

  // old

  orange: `#e47e2a`,

  // by function

  surfaceAsphalt: tealBlue,
  surfaceGravel: cyberYellow,

  // client: stolavsleden

  stolavsledenRed: `#d23833`,
  stolavsledenRedDark: `#822123`,
}
