// TODO: make it work in StrictMode

import React from "react"
import ReactDOM from "react-dom/client"
import { StyleSheetManager, ThemeProvider } from "styled-components"
import * as Sentry from "@sentry/browser"

import App from "./App"
import Store, { StoreProvider } from "./store"
import theme from "./theme"
import embedParams from "./config/embedParams"
import { __ } from "./utils/translate"

document.documentElement.lang = embedParams.language
document.title = `Naturkartan ${__(`Map`)}`

if (process.env.NODE_ENV == `production`)
  Sentry.init({
    dsn: `https://27283e7a46b149faa2732c4ab6918ca5@glitchtip.naturkartan.se/4`,
  })

const store = new Store()

const root = ReactDOM.createRoot(document.getElementById(`root`))

root.render(
  // <React.StrictMode>
  <StoreProvider store={store}>
    <StyleSheetManager enableVendorPrefixes={true}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </StyleSheetManager>
  </StoreProvider>
  // </React.StrictMode>
)
